
import {
    Box,
    Button,
    HStack,
    Stack,
    Badge,
    Spacer,
    Flex,
    Heading,
    VStack,
    Text,
    Center,
    SimpleGrid,
    Spinner,
    useBoolean,
    Container,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
    Link,
    useColorModeValue,
    useColorMode,
    useDisclosure,
    Menu,
    MenuButton,
    Form,
    FormLabel,
    Input,
    MenuList,
    MenuItem,
    MenuDivider,
    Checkbox,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    FormControl,
    RadioGroup,
    Radio,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    TabPanel,
    Image,
    Select
} from "@chakra-ui/react";
import { create } from "zustand";
import { shallow } from "zustand/shallow";
import { subscribeWithSelector } from "zustand/middleware";

import { useAuthState } from "react-firebase-hooks/auth";
import { HTTPGet, HTTPPost } from "../httputils";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from 'react';
import BillingModal from "./BillingModal";
import BillingInfoAlert from "./BillingInfoAlert";
import CustomSproutButton from "./CustomSproutButton";
import { SaveImpactSettings } from "../backend";

import firebase from "../firebase";
export default function AppSettingsV2({ compStore, setCompStore }) {  
  const [showBillingModal, setShowBillingModal] = React.useState(false);
  const [user, loading] = useAuthState(firebase.auth());
  const [unsavedChanges, setUnsavedChanges] = React.useState("");

  const [formStore, setFormStore] = React.useState({
    num_trees: compStore?.current_integration?.impact_settings[0]?.tree_planting?.num_trees || 0,
    planting_option: compStore.current_integration?.impact_settings[0]?.tree_planting?.selected_tree_planting_option || "per_product",
    minimum_order_amount: compStore.current_integration?.impact_settings[0]?.tree_planting?.threshold_amt/100 || 0,
    minimum_order_amount_enabled: compStore.current_integration?.impact_settings[0]?.tree_planting?.threshold_amt > 0,
  });

  React.useEffect(() => {
    setFormStore({
      num_trees: compStore.current_integration?.impact_settings[0]?.tree_planting?.num_trees||0,
      planting_option: compStore.current_integration?.impact_settings[0]?.tree_planting?.selected_tree_planting_option || "per_product",
      minimum_order_amount: compStore.current_integration?.impact_settings[0]?.tree_planting?.threshold_amt/100 || 0,
      minimum_order_amount_enabled: compStore.current_integration?.impact_settings[0]?.tree_planting?.threshold_amt > 0,
    });

  }, []);



  React.useEffect(() => {
    setFormStore({
      num_trees: compStore.current_integration?.impact_settings[0]?.tree_planting?.num_trees||0,
      planting_option: compStore.current_integration?.impact_settings[0]?.tree_planting?.selected_tree_planting_option || "per_product",
      minimum_order_amount: compStore.current_integration?.impact_settings[0]?.tree_planting?.threshold_amt/100 || 0,
      minimum_order_amount_enabled: compStore.current_integration?.impact_settings[0]?.tree_planting?.threshold_amt > 0,
    });

  }, [compStore.current_integration]);

  React.useEffect(() => {
    // determine if current_integraion and formStore are different
    var diff = false
    if (compStore.current_integration?.impact_settings.length == 0) {
      diff = false
      return
    }
    if (compStore.current_integration?.impact_settings[0]?.tree_planting?.num_trees != formStore.num_trees) {
      diff = true
    }
    if (compStore.current_integration?.impact_settings[0]?.tree_planting?.selected_tree_planting_option != formStore.planting_option) {
      diff = true
    }
    if (compStore.current_integration?.impact_settings[0]?.tree_planting?.threshold_amt/100 != formStore.minimum_order_amount) {
      diff = true
    }
    if (compStore.current_integration?.impact_settings[0]?.tree_planting?.threshold_amt > 0 != formStore.minimum_order_amount_enabled) {
      diff = true
    }

    if (diff) {
      setUnsavedChanges("You have unsaved changes")
    } else {
      setUnsavedChanges("")
    }

  
  }, [formStore]);




  
function saveButton() {
  var label = "Save and Activate"

  if (compStore.current_integration?.status == "active") {
    label = "Update"
  }

 
  

  return (
    
    <CustomSproutButton compStore={compStore} setCompStore={setCompStore} color={"teal"} handler={
      async ()=>{
        if (!formStore.minimum_order_amount_enabled || formStore.planting_option != "per_order") {
          formStore.minimum_order_amount = 0
        }
        var resp = await SaveImpactSettings(user.getIdToken(true), compStore.current_integration.uuid, formStore)
        if (!resp) {
          return
        }

        var int_idx = 0
        for (var i = 0; i < resp.length; i++) {
          if (resp[i].uuid == compStore.current_integration.uuid) {
            int_idx = i
            break
          }
        }

        setCompStore({
          ...compStore,
          current_integration: resp[int_idx],
          connected_integrations: resp,
        })


      }
    } label={label} />
  )   
   
}
return (
<Container maxW="1280px">
<BillingModal show={showBillingModal} setShow={setShowBillingModal} compStore={compStore} setCompStore={setCompStore} />


      <Flex
        flexDirection={{ base: "column", md: "row", lg: "row" }}
        gap="6"
      >
       <Box w={{ base: "100%", md: "67%", lg: "67%" }}>
  <Box
    mb={6}
    bgColor="#F4F8FB"
    borderRadius="24"
    p={{ base: "4", md: "8", lg: "8" }}
  >
    <Box mb={6}>
      <Text fontSize="xl" fontWeight="medium" mb={2}>
        Planting Settings
      </Text>
      <Text lineHeight="150%" mb={8} textColor="gray.500">
        It only costs <b>${compStore.costPerTree}0 USD</b> to plant a tree with Sprout.
        Personalize your app settings below to fit your business and
        environmental needs.
      </Text>
    </Box>
    <Box mb={8}>
      <FormControl>
        <FormLabel fontWeight="medium">I want to:</FormLabel>
        <RadioGroup
          colorScheme="teal"
          value={formStore.planting_option}
          onChange={(value) => setFormStore({ ...formStore, planting_option: value })}
        >
          <Stack spacing="8px">
            <Radio value="per_product">
              Plant X tree(s) for <b>every product sold</b>
            </Radio>
            <Radio value="per_order">
              Plant X tree(s) for <b>every order</b>
            </Radio>
            {compStore.current_integration.status == "active" ? (
            <Radio value="pause" >
              Pause
            </Radio>
            ):(
            <Radio value="pause" disabled={
              true
            }>
              Pause
            </Radio>
            )}
          </Stack>
        </RadioGroup>
      </FormControl>
    </Box>
    <Box mb={8}>
      {formStore.planting_option != "pause" ? (
        <>
      <FormLabel fontWeight="medium">How many trees?</FormLabel>
      <NumberInput
        mb={4}
        value={formStore.num_trees}
        min={0}
        onChange={(value) => setFormStore({ ...formStore, num_trees: value })}
      >
        <NumberInputField bgColor="white" borderRadius="10" />
        <NumberInputStepper pr="2">
          <NumberIncrementStepper color="gray.400" border="0" />
          <NumberDecrementStepper color="gray.400" border="0" />
        </NumberInputStepper>
      </NumberInput>
      </>
        ):(

          
          <Text color="red.500">
             Pausing will disable sprout for this integration and hide any installed banners
          </Text>
          
         

        )}
      {formStore.planting_option == "per_order" && (
      <Checkbox
        colorScheme="teal"
        borderColor="gray.400"
        isChecked={formStore.minimum_order_amount_enabled}
        onChange={(event) =>
          setFormStore({
            ...formStore,
            minimum_order_amount_enabled: event.target.checked,
          })
        }
      >
        Set a minimum order amount
      </Checkbox>
      )}
    </Box>
    {formStore.minimum_order_amount_enabled && formStore.planting_option == "per_order" && (
      <Box>
        <FormLabel fontWeight="medium">
          Only plant trees when order exceeds value (in {compStore.current_integration?.currency})
        </FormLabel>
        <NumberInput
          mb={2}
          value={formStore.minimum_order_amount}
          min={1}
          precision={2}
          step={1.0}
          onChange={(value) =>
            setFormStore({ ...formStore, minimum_order_amount: value })
          }
        >
          <NumberInputField bgColor="white" borderRadius="10" />
          <NumberInputStepper>
            <NumberIncrementStepper color="gray.400" border="0" />
            <NumberDecrementStepper color="gray.400" border="0" />
          </NumberInputStepper>
        </NumberInput>
      </Box>
    )}
  </Box>
</Box>

        <Box w={{ base: "100%", md: "33%", lg: "33%" }}>
          <Box
            mb={6}
            bgColor="#F4F8FB"
            borderRadius="24"
            p={{ base: "4", md: "8", lg: "8" }}
          >
            <Box mb={6}>
              <Text fontSize="xl" fontWeight="medium" mb={2}>
                App settings
              </Text>
              <Text color="gray.500" mb={4}>
                Once activated, Sprout will automatically track orders in your store and calculate the number of trees to plant based on your settings
              </Text>
            </Box>
            <Box mb={4}>
         <Stack>
            {formStore.planting_option == "pause" ? (
              <>
              <Box bg="white" p={3}>
                <Text>No trees tracked and charged for</Text>
              </Box>
              <Box bg="white" p={3}>
                <Text>Banners disabled</Text>
              </Box>
              </>
            ):(
              <>
               <Box bg="white" p={3}>
                <Text>Cost ${compStore.costPerTree}0 USD per tree planting </Text>
              </Box>
              </>
            )}
          

         </Stack>
       </Box>
             {saveButton()}
             <br/>
              <Text color={"red.500"}> {unsavedChanges} </Text>
             
                
             
          </Box>
        </Box>
      </Flex>
</Container>
);
}